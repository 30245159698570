import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { Password } from '~/components/ui/input';

import { useIsRequired } from '../../form-atom';
import type { FieldProps, PasswordProps } from './types';

export type PasswordFieldProps = Omit<FieldProps & PasswordProps, 'type'>;

export const PasswordField = ({
  label,
  helpText,
  name,
  defaultValue,
  className,
  ...props
}: Readonly<PasswordFieldProps>) => {
  const isRequired = useIsRequired(name);
  return (
    <FormField
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>
            {label}
            {isRequired && <span className='text-destructive'> *</span>}
          </FormLabel>
          <FormControl>
            <Password {...props} {...field} />
          </FormControl>
          {helpText && <FormDescription>{helpText}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
